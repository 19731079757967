<template>
  <div class="container p-0 m-0">
    <div class="row full-heigth p-0 m-0">
      <loading :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"></loading>
      <div class="card card-fullheight m-0 pb-2">
        <div class="card-body">
          <button type="button" class="royalc-btn-close pt-2 pr-0 float-right" @click="onClose()"
            aria-label="Close modal"><i class="fas fa-times"></i></button>
          <h5 class="box-title mb-1 pb-1">Reservar</h5>
          <hr>

          <!--  Equipamentos (labels) -->
          <div class="row mt-2">
            <div class="col-lg-4 text-left">
              <label for="equipment.name"><small>Equipamento</small></label>
            </div>

            <div class="col-lg-3">
              <label for="equipment.available" class="small grey-label pb-0 mb-0">Disponível</label>
            </div>

            <div class="col-lg-3">
              <label for="equipment.used" class="small grey-label pb-0 mb-0">Utilizar</label>
            </div>
            <div class="col-lg-2"></div>
          </div>

          <!--  Equipamentos (options) -->
          <div v-for="(equipment, index) in equipments" :key="index" class="row">
            <div class="col-lg-4 align-bottom">
              <p name="equipment.name" class="text-left mb-0 mt-3">{{ equipment.name }}</p>
            </div>

            <div class="col-lg-3">
              <custom-input
                v-model="equipment.available"
                name="equipment.available"
                :disabled="true"
                type="number"
                rootClassName="md-form my-0 py-0"
                inputClassName="md-form-control text-center"
                :error="submitted ? errors.first('equipment.available') : ''">
              </custom-input>
            </div>

            <div class="col-lg-3">
              <custom-input
                v-model="equipment.used"
                name="equipment.used"
                type="number"
                rootClassName="md-form my-0 py-0"
                inputClassName="md-form-control text-center"
                :error="submitted ? errors.first('equipment.used') : ''"
                @blur="onLostFocus(index)">
              </custom-input>
            </div>

            <div class="col-lg-2">
              <button class="btn btn-sm btn-outline-light mt-2 py-1" @click="onToogleSelectAll(index)">
                <span v-if="equipment.available != equipment.used"><small>All</small></span>
                <span v-if="equipment.available == equipment.used"><small>X</small></span>
              </button>
            </div>
          </div>

          <hr>

          <!--  Cliente  -->
          <div class="row mt-3">
            <div class="col-12 form-group text-left">
              <label class="small grey-label">Cliente</label>
              <v-select class="grey-field" name="cliente" label="name" :clearable="true"
                :searchable="true" v-model="cliente" :options="customerList"
                @search="customerFetchOptions">
                <template slot="no-options" slot-scope="{searching, search}">
                  <em v-if="!searching" style="opacity: 0.5;">
                    Digite para buscar um cliente
                  </em>
                  <em v-else style="opacity: 0.5;">
                    Nenhum cliente encontrado para {{ search }}
                  </em>
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                  </div>
                </template>
              </v-select>
              <transition name="slide" mode="">
                <small v-if="errors.first('cliente')" class="royalc-error-field">{{ errors.first('cliente') }}</small>
              </transition>
            </div>
          </div>

          <hr>
          <div class="row">
            <div class="col-12 align-center">
              <button class="btn btn-link btn-sm btn-a-color mt-1" @click="onClose()">Cancelar</button>
              <button :disabled="sum < 1" class="btn btn-sm btn-outline-light btn-a-color m-1" @click="onSave()">Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import BookingService from '@/services/BookingService'
import ProposalService from '@/services/ProposalService'

export default {
  name: 'BookingApplyingForm',
  components: {
    Loading,
    CustomInput,
    vSelect
  },
  data () {
    return {
      isLoading: false,
      submitted: false,
      fullPage: true,
      equipsLoaded: false,
      equipments: [],
      equipamentos: [],
      cliente: null,
      customerList: []
    }
  },
  beforeMount () {
    this.getEquipments()
  },
  mounted () {
  },
  methods: {
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    isset (value) {
      return value !== null && value !== undefined && value !== ''
    },
    getEquipments () {
      let _this = this

      return new Promise((resolve, reject) => {
        BookingService.getBookingEquipments(this.booking).then(res => {
          let equips = null

          if (this.option === 'applying') {
            equips = res.data.data.map(x => {
              return {
                id: x.idequipamento_maritimo.id,
                name: x.idequipamento_maritimo.descricao,
                available: x.quantidade,
                used: x.quantidade
              }
            })
          }

          _this.equipments = equips
          _this.equipsLoaded = true
        }).catch((err) => {
          reject(err)
        })
      })
    },
    customerFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
        loading(false)
      })
    },
    onToogleSelectAll (index) {
      let equipment = this.equipments[index]
      if (equipment.available === equipment.used) {
        equipment.used = 0
      } else {
        equipment.used = equipment.available
      }
    },
    onLostFocus (index) {
      let equipment = this.equipments[index]

      if (equipment.used > equipment.available) {
        equipment.used = equipment.available
      }

      if (equipment.used < 0) {
        equipment.used = 0
      }
    },
    onSave () {
      this.submitted = true
      this.$validator.validate().then(valid => {
        if (valid) {
          this.isLoading = true

          let formData = new FormData()
          formData.append('id', this.booking)
          formData.append('equipamentos', JSON.stringify(this.equipments))
          if (this.isset(this.cliente)) {
            formData.append('cliente', this.cliente.id)
          }

          BookingService.applyBooking(formData).then(response => {
            this.$emit('refresh')
            this.onClose()
          }).catch(err => {
            if (err.code === 400) {
              this.displayErrors(err.errors)
            }
            this.isLoading = false
          })
        }
      })
    }
  },
  computed: {
    equipamentsError () {
      return this.submitted && this.booking.equipamentos.length <= 0
    },
    canEdit () {
      return this.isSuperAdmin || this.isBookingAdmin
    },
    isSuperAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isBookingAdmin () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_MANAGER') !== -1) {
        return true
      }
      return false
    },
    isBookingRequester () {
      let userInfo = this.user || null
      if (userInfo && userInfo.roles.indexOf('ROLE_BOOKING_REQUESTER') !== -1) {
        return true
      }
      return false
    },
    isTheSeller () {
      let userInfo = this.user || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idVendedor
      }
      return false
    },
    isTheRequester () {
      let userInfo = this.user || null
      if (userInfo) {
        return userInfo.active_employee.id_table_origin === this.booking.idSolicitante
      }
      return false
    },
    isAvailable () {
      return !this.booking.idVendedor
    },
    isFollowing () {
      return this.subscribers && this.subscribers.includes(this.user.id)
    },
    sum () {
      return this.equipments.map(equip => equip.used)
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)
    }
  }
}
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 15px;
  right: 33px;
}

tr.internal {
  background-color: #fcf8e3;
}

.royalc-btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #84754E;
  background: transparent;
}

.btn-a-color {
  color: #534B41 !important;
}
</style>
